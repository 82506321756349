<template>
  <div>
    <Pane />

    <div class="container">
      <div class="sider">
        <a-input-search
          placeholder="请输入关键词搜索"
          enter-button
          @search="onSearch"
        />
        <div class="menu">
          <div
            class="item"
            :class="form.type === item.value ? 'active' : ''"
            v-for="item in filteredTypeList"
            :key="item.value"
            @click="selectType(item)"
          >
            <span>
              {{ item.name }}
            </span>
            <span v-if="item.num"> ({{ item.num }}) </span>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="list" v-if="list.length > 0">
          <div
            class="item"
            title="选择"
            v-for="item in list"
            :key="item.id"
            @click="select(item)"
          >
            <img :src="item.modelImg ? item.modelImg : defaultImage" alt="" />
            <div class="between footer">
              <div class="name">{{ item.name }}</div>
              <a-checkbox :checked="selected.indexOf(item) > -1"></a-checkbox>
            </div>
          </div>
        </div>
        <div v-else>
          <a-empty />
        </div>
      </div>

      <div class="cart">
        <div class="header">
          <img src="../../../assets/param_icon.png" alt="" />
          <span>构建清单</span>
        </div>

        <div
          class="selected"
          title="选择"
          v-for="item in selected"
          :key="item.id"
          @click="cancelSelect(item)"
        >
          <div class="between footer">
            <div class="name">{{ item.name }}</div>
            <a-icon type="delete" />
          </div>
        </div>

        <div class="footer">
          <a-button
            type="primary"
            block
            :disabled="selected.length === 0"
            @click="start"
            >{{ selected.length === 0 ? "请选择计算书" : "开始设计" }}</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { mapGetters } from "vuex";
import { fetchList, fetchNumByCategory } from "@/api/parameterization/book";
export default {
  mixins: [watermark],
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 100,
      list: [],
      total: 0,

      defaultImage: require("@/assets/param1.png"),

      selected: [],
      filteredTypeList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("bim_calc_book_type");
    },
  },

  mounted() {
    this.form = {
      type: this.typeList[0].value,
    };

    fetchNumByCategory().then((res) => {
      if (typeof res === "object") {
        this.filteredTypeList = this.typeList.map((item) => {
          const list = Object.keys(res);
          let num = 0;
          const key = list.find((key) => key === item.value);
          if (key) {
            num = res[key];
          }
          return {
            ...item,
            num,
          };
        });
      }
    });

    const content = document.querySelector(".content");
    if (content) {
      this.setWatermark(content);
    }
  },

  watch: {
    form() {
      this.getList();
    },
  },
  methods: {
    selectType(item) {
      this.form = {
        ...this.form,
        type: item.value,
      };
    },

    onSearch(name) {
      this.form = {
        ...this.form,
        name,
      };
    },

    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    select(item) {
      const index = this.selected.indexOf(item);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }
    },
    cancelSelect(item) {
      const index = this.selected.indexOf(item);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
    },
    start() {
      window.localStorage.setItem(
        "selected-books",
        JSON.stringify(this.selected)
      );
      this.$close(this.$route.path, `/lab/parameterization/task/add`);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: grid;
  grid-template-columns: 240px 1fr 320px;
  gap: 8px;

  & > div {
    background-color: #fff;
    padding: 16px;
  }

  .sider {
    .menu {
      height: 69vh;
      overflow-y: auto;
      .item {
        cursor: pointer;

        font-size: 13px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        padding: 12px;
        border-bottom: 1px solid #e9e9e9;

        &:last-child {
          border-bottom-width: 0;
        }
      }
      .active {
        color: #1890ff;
      }
    }
  }

  .content {
    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 280px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-right: 16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
        }

        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          width: 100%;
        }
        .footer {
          padding: 12px;
          .name {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }
  }

  .cart {
    .header {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #f0f0f0;

      img {
        width: 14px;
        margin-right: 8px;
      }
    }

    .selected {
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid #e0e0e0;
      &:last-child {
        border-bottom-width: 0;
      }
      .footer {
        padding: 12px;
        .name {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
</style>